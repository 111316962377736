<template>
  <div class="app-container">
    <el-form :inline="true">
      <el-form-item>
        <el-button size="small" type="primary" @click="back">返回</el-button>
      </el-form-item>
    </el-form>
    <div class="detail-container">
      <el-form :inline="true">
        <el-form-item label="交易编号："> {{ historyData.tradeNo }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="说明：">
          {{historyData.tradeTime }} {{ type }}</el-form-item
        >
      </el-form>
      <el-form :inline="true">
        <el-form-item label="变更金额(￥)：">
          {{ $route.query.isIncome == 1 ? '+' + historyData.tradeAmount : '-' + historyData.tradeAmount }}
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="账户余额(￥)："> {{ historyData.remainingAmount }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付时间："> {{ parseTime(historyData.tradeTime) }} </el-form-item>
      </el-form>
    </div>
    <div class="detail-container" style="margin-top: 20px">
      <el-form :inline="true">
        <el-form-item label="流水号："> {{ historyData.thirdpartTradeNo }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付方式："> {{ PAYMENT_TYPE_LABELS[historyData.paymentType] || historyData.paymentType }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="应付金额：">
          <span> ￥{{ historyData.totalAmount }}</span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="实付金额：">
          <span>￥{{ historyData.realAmount }}</span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付状态："> {{ currentState(historyData.paymentStatus) }} </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="支付时间："> {{ parseTime(historyData.tradeTime) }} </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils'
import { getDetails } from '@/api/balanceApi'
import { PAYMENT_TYPE_LABELS } from '@/utils/constant/paymentConst'

export default {
  data() {
    return {
      PAYMENT_TYPE_LABELS,
      historyData: {}
    }
  },
  methods: {
    parseTime,
    back() {
      this.$router.go(-1)
    }
  },
  computed: {
    type() {
      let cur
      if (!this.$route.query.tradeType) return
      let { tradeType } = this.$route.query
      tradeType = Number(tradeType)
      console.log('我被', tradeType)
      switch (tradeType) {
        case 1:
          cur = '充值'
          break
        case 2:
          cur = '提现'
          break
        case 3:
          cur = '订单支付'
          break
        case 4:
          cur = '退款'
          break
        case 5:
          cur = '人工调整余额'
          break
        case 6:
          cur = '提现审核拒绝退款'
          break
      }
      return cur
    },
    currentState() {
      return (state) => {
        let cur
        switch (state) {
          case 0:
            cur = '未支付'
            break
          case 1:
            cur = '已支付'
            break
          case 2:
            cur = '关闭/取消'
            break
        }
        return cur
      }
    }
  },
  async created() {
    const { id } = this.$route.query
    try {
      const { code, detail } = await getDetails({ id })
      if (code != 0) return
      this.historyData = detail
    } catch (error) {}
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 20px 20px;
  .detail-container {
    border: 1px solid #ccc;
    padding: 10px;
  }
}
</style>
